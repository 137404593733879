<template>
  <div class="bfc-market">
    <div class="hero">
      <h1>DS Auto</h1>
    </div>

    <div class="strip">
      <p>December 2020 <span>✹ </span></p>
      <p class="index">002</p>
    </div>

    <div class="info">
      <div class="context">
        <h2>The context</h2>
        <p>
          In december 2020, I had to make a motion design video with the help of 2 classmates in a 3 days mission for the
          <a
            href="https://www.groupechopard.com/"
            target="_blank"
            rel="noopener noreferrer"
            >Chopard group</a
          >
          and
          <a
            href="https://www.dsautomobiles.fr/"
            target="_blank"
            rel="noopener noreferrer"
            >DS Automobiles</a
          >
          as part as a school mission for my second year of digital design bachelor.
        </p>
        <p>
          The goal of this mission was to promote the new DS3 crossback E-Tense motor, wich is the new full electric motor of DS Automobiles, through a short motion design video.
        </p>
      </div>
      <div class="right-info">
        <div class="contribution">
          <h2>My contribution</h2>
          <p>
            My contributions during the project was to create the motion assets for the video. It was necessary to take account of the brand guidelines and to take advantage of it (its strong connexion with Paris, the geometric patern, etc ..) to design a video that's part of DS Automobiles identity.
          </p>
        </div>

        <div class="result">
          <h2>The result</h2>
          <p>
            The Chopard group enjoyed the video we came up with and they used it on their social networks. It was a nice experience wich increased my motion design skills.
          </p>
        </div>
      </div>
    </div>

    <div class="mockup-holder">
      <div class="mockup">
        <video controls src="../../assets/images/projects/ds-auto.mp4"></video>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bfc-market {
  margin: 5rem 2rem;
  @media screen and (max-width: 800px) {
    margin: 0 1rem;
  }
}

h1 {
  font-size: 15vw;
  line-height: 100%;
  word-break: break-all;
  margin: 0;
  @media screen and (min-width: 1500px) {
    font-size: 16rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 8rem;
  }
}

a {
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}

h2 {
  margin: 0;
  font-family: $font-body;
  color: $blue;
  font-size: 4rem;
  font-variation-settings: "wght" 400;
  font-weight: 400;
  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

.hero {
  height: 40vh;
  display: flex;
  align-items: flex-end;
}

.info {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  p {
    padding: 1rem 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
.context,
.right-info {
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.strip {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  p {
    padding: 0.5rem 0;
    font-size: 5rem;
    font-variation-settings: "wght" 300;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
  .index {
    text-align: end;
  }
  span {
    color: $green;
  }
}

.mockup-holder {
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mockup {
  width: 50%;
  max-width: 100rem;
  img, video{
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}
</style>

<script>
export default {
  name: "BFCMarket",
};
</script>
